@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

:root {
  --deep-blue: #1D1CE5;
  --darker-purple: #4649FF;
  --lighter-purple: #7978FF;
  /* --blueviolet: #A22DFF; */
  --blueviolet: #fb7c56;
  --dark-navy: #020c1b;
  --navy: #0a192f;
  --light-navy: #112240;
  --lightest-navy: #233554;
  --navy-shadow: rgba(2,12,27,0.7);
  --dark-slate: #495670;
  --slate: #8892b0;
  --light-slate: #a8b2d1;
  --lightest-slate: #ccd6f6;
  --white: #e6f1ff;
  --green: #64ffda;
  --green-tint: rgba(100,255,218,0.1);
  --pink: #f57dff;
  --blue: #57cbff;

  --font-sans: "Calibre","Inter","San Francisco","SF Pro Text",-apple-system,system-ui,sans-serif;
  --font-mono: "SF Mono","Fira Code","Fira Mono","Roboto Mono",monospace;
  --fz-xxs: 12px;
  --fz-xs: 13px;
  --fz-sm: 14px;
  --fz-md: 16px;
  --fz-lg: 18px;
  --fz-xl: 20px;
  --fz-xxl: 22px;
  --fz-heading: 32px;
  --border-radius: 4px;
  --nav-height: 100px;
  --nav-scroll-height: 70px;
  --tab-height: 42px;
  --tab-width: 120px;
  --easing: cubic-bezier(0.645,0.045,0.355,1);
  --transition: all 0.25s cubic-bezier(0.645,0.045,0.355,1);
  --hamburger-width: 30px;
  --ham-before: top 0.1s ease-in 0.25s,opacity 0.1s ease-in;
  --ham-before-active: top 0.1s ease-out,opacity 0.1s ease-out 0.12s;
  --ham-after: bottom 0.1s ease-in 0.25s,transform 0.22s cubic-bezier(0.55,0.055,0.675,0.19);
  --ham-after-active: bottom 0.1s ease-out,transform 0.22s cubic-bezier(0.215,0.61,0.355,1) 0.12s;
}

body {
  font-family: 'Roboto', sans-serif !important;
  color: #333;
  background-color: #f7f7f7;
  font-size: 18px;
  line-height: 1.66667;
}

a {
  color: #333 !important;
  text-decoration: none !important;
}

.btn:active {
  border: none;
}

/* ----------------------- */

/* Container */

.container-fluid {
  max-width: 1760px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-left: 80px !important;
  padding-right: 80px !important;
}

@media (max-width: 1200px) {
  .container-fluid, .container {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }
}

@media (max-width: 768px) {
  .container-fluid, .container {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }
}

/* ----------------------- */

/* Aspect ratio boxes */

.aspectRatioBox {
  padding-top: calc(1 / 1 * 100%);
  background-size: contain;
  background-repeat: no-repeat; 
  background-position: center;
}

.aspectRatioBox-portfolio {
  padding-top: calc(1 / 2 * 100%);
  background-size: cover;
  background-position: center;
  border-radius: 20px 20px 0 0;
}

.aspectRatioBox-image {
  padding-top: calc(1 / 1 * 100%);
  background-size: cover;
  background-position: center;
}

.aspectRatioBox-location {
  padding-top: calc(1 / 2 * 100%);
  background-size: cover;
  background-position: center;
  border: 1px solid #95a3a4;
}

/* ----------------------- */

/* Row gutters */

.custom-gutter > [class*='col-'] {
  padding-right: 6px;
  padding-left: 6px; 
}

/* ----------------------- */

/* Section titles */

.section-title {
  text-align: center;
  margin-bottom: 36px;
}

.section-title p {
  color: #95a3a4;
  font-size: 16px;
}

.section-title h2 {
  margin-bottom: 0;
}

.section-title span {
  color: var(--deep-blue);
}

/* ----------------------- */

/* Navbar */

#navbar {
  height: 80px;
  border-bottom: 1px solid #d3d3d3;
  z-index: 1;
}

#mdNavbar {
  padding: 0 64px;
}

#xsNavbar {
  padding: 0 8px;
}

.navbar-title {
  color: var(--deep-blue) !important;
  letter-spacing: 3px;
  font-size: 24px;
}

.navbar-title:hover {
  color: var(--deep-blue);
}

.navbar-title-portfolio {
  font-size: 18px;
  margin-left: 6px;
}

/* .nav-link:hover {
  background-color: var(--deep-blue) !important;
  color: #fff !important;
  border-radius: 5px;
} */

#navbarMenuToggler {
  position: relative;
  font-size: 24px;
  margin-right: 0;
  padding: 0 8px;
  transition: color 0.25s;
  z-index: 1;
}

#navbarMenuToggler i:hover {
  color: var(--deep-blue);
}

#navbarMenu {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 48px 18px;
  width: 200px;
  background-color: #fff;
  box-shadow: 0 6px 20px rgb(0 0 0 / 20%);
  border: 1px solid rgba(0,0,0,.04);
  border-radius: 16px;
  text-align: center;
}

.xsMenu-listItem {
  margin-top: 24px;
}

.xsMenu-listItem a {
  display: block;
  margin: 0 40px;
}

.xsMenu-listItem a:hover {
  color: var(--deep-blue);
  text-decoration: none;
  border-bottom: 1px solid var(--deep-blue);
}

@media (max-width: 1200px) {
  #mdNavbar {
    padding: 0 24px;
  }
}

@media (min-width: 768px) {
  #navbarMenu {
    display: none !important;
  }
}

/* ----------------------- */

/* Hero */

.hero h1 {
  margin: 0px 0px 30px 4px;
  color: var(--blueviolet);
  /* font-family: var(--font-mono); */
  font-size: 16px;
  font-weight: 400;
}

.hero h2 {
  font-weight: 600;
  color: var(--darker-purple);
  line-height: 1.1;
}

.hero h3 {
  margin-top: 10px;
  color: var(--lighter-purple);
  line-height: 0.9;
}

.big-heading {
  margin: 0px;
  font-size: clamp(40px, 8vw, 80px);
}

.hero p {
  margin: 20px 0px 0px;
  max-width: 540px;
}

.btn-portfolio {
  vertical-align: baseline !important;
  padding: 0 !important;
  margin-left: 4px;
  border: none;
}

.hover-underline-animation {
  display: inline-block !important;
  position: relative;
  color: var(--deep-blue) !important;
}

.hover-underline-animation::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--deep-blue);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.hero-wrap {
  margin-top: 24px;
}

.hero-text-wrap {
  margin-left: 48px;
  margin-right: 48px;
}

.intro-wrap {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-image: linear-gradient(90deg, rgba(26, 0, 255, 0.9) 50%, rgba(255,255,255,1) 100%);
  border-image-slice: 1;
}

.intro-wrap p {
  font-size: 14px;
}

.intro-wrap b {
  color: #c300ff;
}

.name {
  color: #c300ff;
}

.become-paragraph {
  margin-top: 16px;
}

.become-paragraph a {
  color: #c300ff;
  text-decoration: underline;
}

.social-wrap {
  margin-top: 50px;
}

.btn-download-cv {
  background-color: var(--deep-blue) !important;
  color: #fff !important;
  margin-right: 24px;
  font-size: 16px !important;
  height: 100%;
  padding: 12px !important;
}

.btn-download-cv:hover {
  background-color: #fb7c56 !important;
  /* transition: 3s ease-in; */
}

.social-wrap p {
  color: #95a3a4;
  font-size: 14px;
  margin-bottom: 0;
}

.social-wrap a {
  font-size: 24px;
}

.social-wrap a:hover {
  color: var(--deep-blue);
}

.social-wrap i {
  margin-right: 28px;
}

@media (max-width: 1200px) {
  .big-heading {
    font-size: clamp(40px, 8vw, 74px);
  }
}

@media (max-width: 992px) {
  .big-heading {
    font-size: clamp(40px, 8vw, 68px);
  }

  .hero-text-wrap {
    margin-left: 36px;
    margin-right: 36px;
  } 

  .intro-wrap h2 {
    font-size: 28px;
  }

  .become-paragraph {
    font-size: 16px;
  }
}

/* ----------------------- */


.article-image {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.article-image img {
  max-width: 100%;
  height: auto;
  border-radius: 20px 20px 0 0;
}

.article-card {
  background-color: #fff;
  border-radius: 20px;
  margin-bottom: 36px;
  transition: 0.3s;
}

.article-card:hover {
  box-shadow: 0 4px 20px 0 rgba(34, 68, 123, 0.2);
  transform: translateY(-3px);
}

.article-content {
  padding: 2rem;
  min-height: 370px !important;
}

.article-content .card-category {
  font-size: 0.675rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 600;
  color: var(--darker-purple);
  display: block;
  text-decoration: none;
  min-height: 34px;
}

.article-content .card-title {
  font-size: 20px;
  margin: 1rem 0;
}

.card-excerpt {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #333;
  margin: 0;
}

@media (max-width: 1200px) {
  .article-content {
    min-height: 350px !important;
  }
}

@media (max-width: 768px) {
  .article-content {
    min-height: 300px !important;
  }
}

/* Portfolio */

.portfolio-bg,
.contact-bg,
.footer-bg {
  background-color: #f7f7f7
}

.portfolio-wrap {
  margin-top: 80px;
  margin-bottom: 36px;
  padding-top: 120px;
  padding-bottom: 80px;
}

.portfolio-box {
  position: relative;
  background: linear-gradient(0deg, #0093E9 0%, #80D0C7 40%, #ffffff 100%);
  margin-bottom: 24px;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .2s ease;
  background-color: #fff;
}

/* .article-card:hover .overlay {
  opacity: 0.3;
} */

.portfolio-box-inner {
  padding: 24px;
}

.portfolio-box-title {
  margin-bottom: 0;
}

.portfolio-lang {
  margin-bottom: 24px;
}

.portfolio-html, .portfolio-css {
  font-size: 11px;
  padding: 0 4px;
  border-radius: 12px;
}

.portfolio-html {
  border: 1px solid #c300ff;
  color: #c300ff;
}

.portfolio-css {
  border: 1px solid #4B00C8;
  color: #4B00C8;
}

/* ----------------------- */

/* About */

.about-wrap {
  margin-top: 80px;
  padding-top: 24px;
  padding-bottom: 24px;
}

.about-intro h5, .about-skills h5 {
  font-size: 18px;
}

.about-intro {
  text-align: end;
  margin-left: 40px;
}

.about-intro h5 {
  color: var(--lighter-purple);
}

.about-intro span {
  /* color: #95a3a4; */
  color: var(--darker-purple);
}

.about-intro h6 {
  /* color: #95a3a4; */
  color: var(--blueviolet);
}

.about-intro p {
  font-size: 14px;
}

.read-interview-btn {
  background-color: var(--deep-blue) !important;
  color: #fff !important;
  margin-top: 12px;
}

.read-interview-link {
  font-size: 14px;
  color: var(--deep-blue) !important;
}

.about-image {
  border: 1px solid;
  border-image: linear-gradient(90deg, rgba(26, 0, 255, 0.9) 50%, rgba(255,255,255,1) 100%);
  border-image-slice: 1;
}

.skill-title {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 600;
  /* color: var(--deep-blue); */
  color: var(--darker-purple);
  display: block;
  width: 200px;
}


.skill {
  margin-top: 22px;
}

.skill-list p {
  /* border: 1px solid rgba(26, 0, 255);
  color: rgba(26, 0, 255);
  border-radius: 5px; */
  color: var(--lighter-purple);
  padding: 2px 8px;
  margin: 0 4px 8px 4px;
  font-size: 14px;
}

@media (max-width: 992px) {
  .skill {
    margin-top: 16px;
  }
}

@media (max-width: 768px) {
  .about-intro {
    text-align: start;
    margin-left: 0;
  }

  .about-image {
    margin: 40px;
  }
  
  .about-skills {
    margin-bottom: 36px;
  }


}

/* ----------------------- */

/* Contact */

.background-contact {
  margin-top: 80px;
  background-color: #f7f7f7;
}

.contact-wrap {
  margin-top: 80px;
  padding-top: 80px;
  padding-bottom: 48px;
}

.contact-inner {
  background-color: #f7f7f7;
  padding: 24px 0;
}

.contact {
  text-align: center;
  background-color: #fff;
  padding: 24px 12px;
  height: 100%;
}

.contact h5 {
  letter-spacing: 3px;
  font-size: 14px;
  margin-bottom: 24px;
}

.contact p {
  font-size: 14px;
}

.contact-email p {
  font-size: 16px;
}

.contact-email, .contact-social {
  margin-top: 24px;
}

.contact-email h6, .contact-social h6 {
  margin-bottom: 0px;
}

.contact-email i, .contact-social i {
  font-size: 24px;
  color: #95a3a4;
  margin-bottom: 12px;
}

.contact-social-icons a {
  width: 50px;
  height: 50px;
  border: 1px solid #333;
  border-radius: 50%;
  font-size: 24px;
  margin: 0 4px;
  background: 0 0;
  text-align: center;
}

.contact-social-icons .icon-social {
  margin-top: 12px;
}

.contact-email a:hover {
  color: var(--deep-blue);
  text-decoration: none;
}

.contact-social-icons a:hover {
  border: 1px solid var(--deep-blue);
  color: var(--deep-blue) !important;
}

.send-message {
  margin: 0 16px;
}

.send-message-field input, .send-message-field textarea {
  width: 100%;
  border: 1px solid #95a3a4;
}

.send-message-button {
  background-color: var(--deep-blue) !important;
  color: #fff !important;
  margin-top: 12px;
  min-height: 50px;
}

.send-message-button:hover {
  background-color: var(--blueviolet) !important;
  color: #fff;
}

@media (max-width: 992px) {
  .contact-column {
    margin-bottom: 30px;
  }

}

@media (max-width: 768px) {
  .contact-wrap {
    padding-top: 80px;
  }
}

/* ----------------------- */

/* Footer */

.footer-inner {
  color: #95a3a4;
  font-size: 14px;
  border-top: 1px solid #95a3a4;
  padding: 24px 0;
}

footer .btn {
  color: #95a3a4;
  font-size: 24px;
}

footer .btn:hover {
  color: var(--deep-blue);
}

/* ----------------------- */
